<script setup lang="ts">
// types
import type { Nullable } from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// vuetify
import { useDisplay, useTheme } from 'vuetify'

// config
import generateMenuItems from '@/config/navigation'

// stores
import { useCourseMaterialsStore } from '@/store/course-materials'
import { useGlobalStore } from '@/store/global'
import { useTrialStore } from '@/store/trial'

// composables
import { useScheduleSessionsV2 } from '@/composables/scheduleSessionsV2'

/**
 * nuxt runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * vuetify
 * ==================================================================
 */
const { lgAndUp } = useDisplay()
const vuetifyTheme = useTheme()

/**
 * state
 * ==================================================================
 */
const capturedError = ref<Nullable<Error>>(null)
const drawer = ref(lgAndUp)
const renderError = ref(false)

/**
 * stores
 * ==================================================================
 */
const courseMaterialsStore = useCourseMaterialsStore()
const { courseMaterials } = storeToRefs(courseMaterialsStore)

const enrollmentStore = useEnrollmentStore()

const globalStore = useGlobalStore()
const {
  isLoading,
  menuItems,
  showSelfMatchHelpDialog
} = storeToRefs(globalStore)

const noticeStore = useNoticeStore()
const {
  headsUpNotice,
  newNotice,
  showHeadsUpNoticeSnackbar,
  showNewNoticeSnackbar
} = storeToRefs(noticeStore)

const trialStore = useTrialStore()
const { isActiveTrial, activeTrialDays } = storeToRefs(trialStore)

const sessionStore = useSessionStore()
const { liveSessions } = storeToRefs(sessionStore)

const studyAreaStore = useStudyAreaStore()

const subjectStore = useSubjectStore()

/**
 * composables
 * ==================================================================
 */
const { isMobile } = useDevice()

const { doHandleError } = useErrorHandler()

const {
  actor,
  actorId,
  isLoggedIn,
  scheduleSessionsStep,
  steps
} = useScheduleSessionsV2()

/**
 * computed
 * ==================================================================
 */
// styles
const containerClasses = computed(() => {
  return isLoggedIn.value
    ? 'container mx-auto h-100'
    : 'container pa-0 d-flex align-center justify-center'
})
const mainStyles = computed(() => {
  return {
    background: `${vuetifyTheme.current.value.colors.backgroundgrey}`,
    height: '100%'
  }
})

// sessions
const liveSession = computed(() => {
  if (!liveSessions.value.length) {
    return
  }
  return liveSessions.value[0]
})

/**
 * methods
 * ==================================================================
 */
// navigation drawer
function doToggleDrawer () {
  drawer.value = !drawer.value
}

// snackbars
function toggleSnackbarHeadsUpNotice (val: boolean) {
  showHeadsUpNoticeSnackbar.value = val
}

function toggleSnackbarNewNotice (val: boolean) {
  showNewNoticeSnackbar.value = val
}

/**
 * lifecycle hooks
 * ==================================================================
 */
onBeforeMount(() => {
  isLoading.value = true
})

onMounted(async () => {
  // set loading false to hide skeleton-loaders
  isLoading.value = false

  // fetch course materials
  try {
    await courseMaterialsStore.index()
  } catch (error) {
    doHandleError(error as FetchError)
  }

  // set menu items
  menuItems.value = generateMenuItems(courseMaterials.value)
})

/**
 * data fetching
 * ==================================================================
 */
await useLazyAsyncData('schedule-sessions-v2-layout',
  async () => {
    try {
      await Promise.all([
        subjectStore.index({
          params: {
            grade: actor.value?.grade
          }
        }),
        enrollmentStore.index({
          params: {
            active: true
          }
        }),
        studyAreaStore.index({
          params: {
            studentId: actorId.value,
            archived: false,
            include: 'subject'
          }
        }),
        sessionStore.index({
          params: {
            attending: true,
            include: 'supervisor,zoom_meeting,place,course,subject',
            language: 'en',
            per: 10,
            schedule: 'live'
          }
        },
        'live'
        )
      ])
    } catch (errorResponse) {
      doHandleError(errorResponse as FetchError)
    }
  }
)

/**
 * lifecycle hooks
 * ==================================================================
 */
onErrorCaptured((
  err: Error,
  _instance: ComponentPublicInstance | null,
  info: string
) => {
  doHandleError(err)
  capturedError.value = err
  renderError.value = info === 'render'
  return false
})
</script>

<template>
  <v-app>
    <div
      v-if="isLoggedIn"
      style="height: 100%;"
    >
      <div
        v-if="isLoading"
        class="d-flex"
        style="height: 100%;"
      >
        <div
          v-if="!isMobile"
          class="d-flex flex-column"
          style="width: 256px; border-right: 0.75px solid #E4E4E4;"
        >
          <v-skeleton-loader
            v-for="i in 6"
            :key="`loader-${i}`"
            type="list-item"
            width="256px"
            height="56px"
            style="border-right: 0.75px solid #E4E4E4; border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
        </div>
        <div
          class="d-flex flex-column"
          style="width: 100%;"
        >
          <v-skeleton-loader
            v-if="isLoading"
            type="list-item"
            width="100%"
            height="56px"
            style="border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
          <v-skeleton-loader
            v-if="isLoading"
            width="100%"
            height="calc(100vh - 126px)"
            style="border-radius: 0px;"
          />
          <v-skeleton-loader
            v-if="isLoading"
            type="list-item"
            width="100%"
            height="70px"
            style="border-top: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
          />
        </div>
      </div>
      <template v-if="!isLoading">
        <lazy-r-navigation-drawer
          :actor="actor"
          :app-stage="config.public.appStage"
          :app-version="config.public.appVersion"
          :drawer="drawer"
          :menu-items="menuItems"
          :settings="config"
          @toggle-drawer="doToggleDrawer"
        >
          <template #bottom>
            <div
              v-if="isActiveTrial"
              class="mt-auto pa-2"
            >
              <v-chip
                class="w-100 border-transparent"
                color="primary"
                border
                label
              >
                <span class="d-flex gap-8 align-center">
                  <AppIcon
                    name="InfoFilled"
                    size="16"
                  />
                  <span class="text-textblack font-12">Trial expires in
                    <span class="font-weight-bold"> {{ activeTrialDays }} days.</span>
                  </span>
                </span>
              </v-chip>
            </div>
          </template>
        </lazy-r-navigation-drawer>
        <client-only>
          <LazyAppBar @toggle-drawer="doToggleDrawer" />
        </client-only>
        <lazy-r-snackbar-heads-up-notice
          v-if="headsUpNotice"
          :show="showHeadsUpNoticeSnackbar"
          :notice="headsUpNotice"
          @toggle-heads-up-snackbar="toggleSnackbarHeadsUpNotice"
        />
        <lazy-r-snackbar-new-notice
          v-if="newNotice"
          :show="showNewNoticeSnackbar"
          :notice="newNotice"
          @toggle-new-notice-snackbar="toggleSnackbarNewNotice"
        />
        <v-main
          :style="mainStyles"
          class="mb-4"
        >
          <LazyBannerLiveSession
            v-if="liveSession"
            :session="liveSession"
          />
          <v-container
            class="d-flex flex-column"
            :class="containerClasses"
            :fill-height="!isLoggedIn"
          >
            <div v-if="!renderError">
              <LazyStepper
                :steps="steps"
                :value="scheduleSessionsStep"
              />
            </div>
            <slot v-if="!isLoading && !renderError" />
            <r-error-display
              v-else-if="renderError && capturedError"
              :error="capturedError"
            />
          </v-container>
          <DialogSelfMatchHelp
            :show="showSelfMatchHelpDialog"
            @toggle-dialog="showSelfMatchHelpDialog = !showSelfMatchHelpDialog"
          />
        </v-main>
      </template>
    </div>
  </v-app>
</template>

<style scoped lang="scss">
@media (min-width: 960px) {
  .container {
    width: 80vw;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .container {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .container {
    width: 95vw;
  }
}

.skeleton-loader-chip :deep(.v-skeleton-loader__text) {
  height: 1.375rem;
  margin: 0;
}
</style>
