<script setup lang="ts">
// vuetify
import { useDisplay, useTheme } from 'vuetify'

// utilities
import { formatPhone } from '@revolutionprep/utils'

/**
 * props
 * ==================================================================
 */
interface Props {
  show: Boolean
}
const props = defineProps<Props>()

/**
 * runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits(['toggle-dialog'])

/**
 * vuetify
 * ==================================================================
 */
const vuetifyTheme = useTheme()
const { xs } = useDisplay()
</script>

<template>
  <r-dialog
    :text-class="`${xs ? '' : 'dialog-text'}`"
    :show="props.show"
    :max-width="530"
    @toggle-dialog="emit('toggle-dialog', $event)"
  >
    <template #content>
      <div :class="`d-flex justify-center w-100 ${xs ? 'pb-7' : 'pb-10'}`">
        <img
          src="~/assets/images/schedule-sessions/bubble-question-mark.svg"
          alt="Chat bubble with a question mark"
        >
      </div>
      <h2 class="text-center pb-3">
        Need help with Self-Match?
      </h2>
      <p class="text-center">
        Feel free to contact our
        <strong>Customer Service Team</strong>
      </p>
      <div class="card-contact my-6 py-5 px-8">
        <p class="card-contact-title pb-2">
          Phone Number
        </p>
        <div class="d-flex justify-center align-center pb-4">
          <AppIcon
            class="me-2 text-black"
            name="Phone"
            size="16"
          />
          <p class="contact-phone">
            {{ formatPhone(config.public.companyPhone) }}
          </p>
        </div>
        <v-divider />
        <div>
          <p class="card-contact-title pt-4 pb-2">
            Email
          </p>
          <div class="d-flex justify-center align-center">
            <AppIcon
              class="me-2 text-black"
              name="Mail"
              size="16"
            />
            <a
              class="contact-email"
              :href="`mailto:${config.public.customerServiceEmail}`"
            >
              {{ config.public.customerServiceEmail }}
            </a>
          </div>
        </div>
      </div>
      <p class="questions">
        If you have any other questions, see
        <nuxt-link
          to="/help"
          :style="{
            color: vuetifyTheme.current.value.colors.secondary,
            textDecoration: 'none',
            'font-weight': 600
          }"
          target="_blank"
        >
          Session FAQs.
        </nuxt-link>
      </p>
    </template>
  </r-dialog>
</template>

<style scoped>
.card-contact {
  background-color: #F5F5F5;
  border-radius: 4px;
}

.card-contact-title {
  color: #808080;
  font-size: 0.875rem;
}

.contact-email {
  color: rgb(var(--v-theme-textblack));
  font-weight: 700;
  text-decoration: none;
}

.contact-phone {
  color: rgb(var(--v-theme-textblack));
  font-size: 0.875rem;
  font-weight: 700;
}

.questions {
  font-size: 0.875rem;
}

p {
  text-align: center;
}
</style>

<style>
.v-card-text.dialog-text {
  padding: 0px !important;
  margin-bottom: 40px;
  margin-left: 72px;
  margin-right: 72px;
}
</style>
